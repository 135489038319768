import axios from 'axios';
import { message } from 'antd';
import { redirectToPassport } from '@common/utils';
import api, { setRedirectToPassport } from 'xiangxin-design/lib/http/axios';

// const api = axios.create({
//   baseURL: '/api',
// });
// api.interceptors.response.use(
//   (response) => {
//     const { error_code, error_msg } = response.data;
//     switch (error_code) {
//       case 11052:
//       case 11051:
//         message.error(error_msg);
//         const redirectTimer = setTimeout(() => {
//           clearTimeout(redirectTimer);
//           redirectToPassport();
//         }, 1500);
//       default:
//         error_code && message.error(error_msg);
//         return response.data;
//     }
//   },
//   (error) => {
//     return Promise.reject(error);
//   },
// );

setRedirectToPassport(redirectToPassport);

function responseInterceptors(response) {
  const { error_code, error_msg } = response.data;
  switch (error_code) {
    case 11052:
    case 11051:
      message.error(error_msg);
      const redirectTimer = setTimeout(() => {
        clearTimeout(redirectTimer);
        redirectToPassport();
      }, 1500);
    default:
      return response.data;
  }
}

export default api;

export * from './menu';
export * from './staff';
export * from './yard';
export * from './common';
export * from './order';
export * from './wharf';
export * from './map';
export * from './driver';
